import React, { useRef, useState } from 'react'
import PackagesAccordion from '../PackagesAccordion'
import UiHandler from '../UiHandler'
import ReactTable from '../ReactTables/ReactTable'
import useGet from '../../hooks/useGet'
import { getErrors, hasData, toastDefault } from '../../utils/handler.utils'
import { voucherTableColumns } from '../../utils/reactTableColumns'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import axiosInstance from '../../common/axios'

function Vouchers() {
    const [uniqueState, setUniqueState] = useState(false)
    const [vouchers, loadingVouchers, errorVouchers] = useGet(`admin/vouchers`, '', uniqueState)

    const onUpdate = () => {
        setUniqueState(prev => !prev);
    }

    const softDeleteVoucher = () => {
        if (window.confirm('Are you sure')) {
            toast.promise(
                axiosInstance.get(`admin/vouchers-delete/used`),
                {
                    pending: 'Deleting voucher...',
                    error: {
                        render(err) {
                            return "Something went wrong"
                        }
                    },
                    success: {
                        render() {
                            return 'Voucher deleted successfully'
                        }
                    }
                },
                toastDefault
            )
        }
    }

    return (
        <section className="relative container_admin" >
            <div className="bg-white overflow-hidden rounded">
                <div className="px-6 py-3 border-b border-gray-200">
                    <h3 className="text-lg font-bold text-black">
                        Vouchers
                    </h3>
                </div>
                <div className="md:px-6 my-10 md:max-w-[1000px] min-h-[200px] md:mx-auto" >
                    <div className='m-3 mb-5'>
                        <button className="cstm_btn_small text-xs" onClick={() => softDeleteVoucher()}>Delete All Used Voucher</button>
                    </div>
                    <div className="rounded relative overflow-hidden">
                        <div>
                            <UiHandler data={vouchers} loading={loadingVouchers} error={errorVouchers} />
                            {
                                hasData(vouchers, loadingVouchers) && (
                                    <>
                                        {vouchers?.map((voucher, i) => (
                                            <PackagesAccordion title={voucher?.name} key={i}>
                                                <PackagesUnderProduct onUpdate={onUpdate} product={voucher} />
                                            </PackagesAccordion>
                                        ))}
                                    </>
                                )
                            }
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Vouchers


const PackagesUnderProduct = ({ product, onUpdate }) => {
    const vouchers = product.Vouchers;

    const deletePackageHandler = (id) => {
        if (window.confirm('Are you sure')) {
            toast.promise(
                axiosInstance.get(`/admin/vouchers/delete/${id}`),
                {
                    pending: 'Deleting voucher...',
                    error: {
                        render(err) {
                            console.log(err);
                            return getErrors(err.data, false, true)
                        }
                    },
                    success: {
                        render() {
                            onUpdate();
                            return 'Voucher deleted successfully'
                        }
                    }
                },
                toastDefault
            )
        }
    }


    const withActionButton = [
        ...voucherTableColumns,
        {
            id: 'edit',
            Header: "Action",
            accessor: 'id',
            Cell: (e) => {
                return <ul className="flex space-x-2">
                    <Link to={`/voucher/edit/${e.value}`} className="cstm_btn_small">
                        Edit
                    </Link>
                    <li className="cstm_btn_small btn_red" onClick={() => deletePackageHandler(e.value)}>
                        Delete
                    </li>
                </ul>
            }
        },
    ]

    return (
        <>
            {/* <UiHandler data={packages} loading={loading} error={error} /> */}
            <div className="absolute top-4 left-4 flex items-center space-x-4" >
                <Link to={`/voucher/add/${product.id}`} className="cstm_btn_small text-xs" >Add new voucher</Link>
            </div>
            {hasData(vouchers) && (
                <div className="mt-4">
                    <ReactTable tableId={`package_${product.id}_table`} data={vouchers} columns={withActionButton} />
                </div>
            )}
        </>
    )
}
