import React, { useRef, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosInstance from '../../common/axios';
import useGet from '../../hooks/useGet';
import useUpload from '../../hooks/useUpload';
import { getErrors, hasData, toastDefault } from '../../utils/handler.utils';
import Loader from '../Loader/Loader';
import ErrorIndicator from '../react-table/ErrorIndicator';
function EditCategory(props) {
    const history = useHistory()
    const categoryId = props.match.params.id;

    const [loading, setLoading] = useState(null)
    const [data, loadingData, error] = useGet(`admin/category/${categoryId}`)
    const [categoryImage, setCategoryImage] = useState(data?.image)
    const { path, uploading } = useUpload(categoryImage)

    const name = useRef(null);
    const image = useRef(null);
    const is_active = useRef(null);
    const sortOrder = useRef(null);


    const editPaymentMethodHandler = (e) => {
        e.preventDefault()
        setLoading(true)
        axiosInstance.post(`/admin/category/update/${categoryId}`, {
            name: name.current.value,
            image: path || data?.image,
            sort_order: sortOrder.current.value,
            is_active: is_active.current.checked ? 1 : 0,
        }).then(res => {
            toast.success('Category updated successfully', toastDefault)

            setTimeout(() => {
                history.push('/category')
            }, 1500);
        }).catch(err => {
            toast.error(getErrors(err, false, true), toastDefault)
            setLoading(false)
        })
    }

    return (
        <section className="relative container_admin" >
            <div className="bg-white overflow-hidden rounded">
                <div className="px-6 py-3 border-b border-gray-200">
                    <h3 className="text-lg font-bold text-black">
                        Edit category
                    </h3>
                </div>
                <div className="py-10 px-4" >
                    <div className="w-full md:w-[70%] min-h-[300px] mx-auto py-6 relative border border-gray-200 px-4">
                        {loadingData && <Loader absolute />}
                        {loading && <Loader absolute />}
                        {error && <ErrorIndicator error={getErrors(error, true)[0]} />}
                        {
                            hasData(data, loading, error) && (
                                <form onSubmit={editPaymentMethodHandler} >
                                    <div>

                                        <div>
                                            <label htmlFor="name">Note</label>
                                            <input ref={name} id="name" defaultValue={data?.name} className="form_input" type="text" placeholder="Title" required />
                                        </div>


                                        <div>
                                            <label htmlFor="image">Category</label>
                                            <input ref={image} id="image" className="form_input" type="file" onChange={e => setCategoryImage(e.target.files[0])} />
                                        </div>

                                        <div className="form_grid">
                                            <div>
                                                <label htmlFor="sort_order">Sort Order</label>
                                                <input ref={sortOrder} defaultValue={data?.sort_order} id="sort_order" className="form_input" type="number" placeholder="Quantity " required />
                                            </div>
                                
                                        </div>


                                        <div className="cursor-pointer" >
                                            <input ref={is_active} id="is_active" defaultChecked={data?.is_active == 1} type="checkbox" className="mr-2" />
                                            <label htmlFor="is_active" className="select-none cursor-pointer">Is Active</label>
                                        </div>

                                        <div className="mt-4">
                                            <button type="submit" disabled={uploading} className="cstm_btn w-full block">Updated Category</button>
                                        </div>
                                    </div>
                                </form>
                            )
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default withRouter(EditCategory)